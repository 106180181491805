import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = ({ data, location }) => {
    const siteTitle = data.site.siteMetadata.title
    const social = data.site.siteMetadata.social
    const description = data.site.siteMetadata.description

    return (
        <Layout location={location} title={siteTitle}>
            <SEO title="About" />
            <h3>About</h3>
            <p>
                {description}. Originally a product of quarantined free time, so coronavirus and related topics
                have been common. Just a place to share whatever I've been thinking about or working on. Data
                science, politics, books, hobbies, etc.
            </p>
            <p>
                I live in San Francisco and do data related things for work.
                I like to ride bikes, all kinds.
                I'm quoted in a dictionary as an example of incorrectly using "dice" as a singular.
            </p>
            <p>
                If you found something you read here interesting, let me know!
            </p>
            <h3>Contact</h3>
            <p>
                To find out about new blog posts and inane thoughts, or to DM me, follow me on <a href={`https://twitter.com/${social.twitter}`}>Twitter</a>.
            </p>
            <p>
                You can also <Link to={'/subscribe'}>subscribe</Link> to get updates via email.
            </p>

        </Layout>
    )
}

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        social {
            twitter
            instagram
            email
        }
      }
    }
  }
`